
export default {
    scrollToTop: true,
    props: {
        urmSource: {
            type: String,
            default: 'find-garage-page'
        },
        source: {
            type: String,
            default: 'static'
        },
        headTitle: {
            type: String,
            default: 'Check latest MOT prices live below'
        },
        fromStation: {
            type: Boolean,
            default: false
        },
        station: {
            default: null
        },
        flipButtons:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            motType: 1,
            post_code: null,
            your_name: null,
            email_address: null,
            phone_number: null,
            reg_no: null,
            terms: false,
            isFromStation: this.fromStation,
            bgc: {
                backgroundColor: 'white'
            },
            isRegistering: false,
            enteredOTP: '',
            otpNotMatched: false,
            canSendOTP: true,
            interval: null,
            selectedStationId: -1,

            loading: false,
            resendText: 'click here to resend.',
            imageText: 'Searching local MOT Garages...',
            OTP: null,

            submitSuccess: false,
            submitFailure: false,


            bookingData: {
                selectedStation: this.fromStation ? this.station : null,
                stations: [],
                reg_no: '',
                your_name: '',
                email_address: '',
                phone_number: '',
                post_code: '',
                disableSubmitButton: true,
                noStations: false,
                mailData: {},
                registered: false,
                OTP: '',
                uid: null,
                cmSource: null,
                source: this.source,
                urm_source: this.urmSource,
            },


        };
    },
    mounted() {
        if (localStorage.getItem("cmsource") != (null || undefined)) {
            this.bookingData.cmSource = localStorage.getItem("cmsource");
        }
    },
    methods: {
        // Validate and Submit form data to a add category
        sendDetails() {
            // Validate all fields for making the API call, throw error and not call API if validation errors are present
            this.$refs.bookMotForm.validate().then(success => {
                if (!success) {
                } else {
                    if(this.isFromStation){
                        this.$bvModal.show('userModal')
                    }
                    else{
                    // If Valid form
                    this.imageText = "Searching local MOT Garages..."
                    setTimeout(() => {
                        this.imageText = "Updating latest prices..."
                    }, 2000)
                    setTimeout(() => {
                        this.imageText = "Searching for offers..."
                    }, 4000)
                    setTimeout(() => {
                        this.imageText = "Loading Results..."
                    }, 6000)


                    this.submitSuccess = false;
                    this.submitFailure = false;
                    if(!this.isFromStation)
                    this.bookingData.selectedStation = ""
                    this.bookingData.registered = false;
                    this.bookingData.noStations = false;

                    this.loading = true;
                    this.bookingData.reg_no = this.bookingData.reg_no.replace(/ /g, '')
                    this.getBasicVehicleDetailsForVisitor(this.bookingData.reg_no);
                }
                }
            });
        },


        // when called from station details page
        sendUserDetails() {
            // Validate all fields for making the API call, throw error and not call API if validation errors are present
            this.$refs.userForm.validate().then(success => {
                if (!success) {
                } else {
                    this.$bvModal.hide('userModal')
                    // If Valid form
                    this.imageText = "Searching local MOT Garages..."
                    setTimeout(() => {
                        this.imageText = "Updating latest prices..."
                    }, 2000)
                    setTimeout(() => {
                        this.imageText = "Searching for offers..."
                    }, 4000)
                    setTimeout(() => {
                        this.imageText = "Loading Results..."
                    }, 6000)

                    this.submitSuccess = false;
                    this.submitFailure = false;
                    if(!this.isFromStation)
                    this.bookingData.selectedStation = ""
                    this.bookingData.registered = false;
                    this.bookingData.noStations = false;

                    this.loading = true;
                    this.bookingData.reg_no = this.bookingData.reg_no.replace(/ /g, '')
                    this.getBasicVehicleDetailsForVisitor(this.bookingData.reg_no);
                }
            });
        },
        // when called from station details page end

        async getBasicVehicleDetailsForVisitor(reg_no) {
            let vehicleData = await fetch(this.$config.fetchUrl + '/api/visitors/getVehicleDetails/' + reg_no)
                .then(res => res.json()).catch(error => {
                    console.error("There was an error!", error);
                });
            if (vehicleData) {
                this.passToBookMot(vehicleData)
            }
            else {
                this.passToBookMot(null)
            }
        },

        passToBookMot(vehicleData) {

            let mailData = {
                vehicleRegistration: this.bookingData.reg_no,
                vehicleMake: "NA",
                vehicleModel: "NA",
                firstUsedDate: "NA",
                manufactureYear: "NA",
                fuelType: "NA",
                primaryColor: "NA",
                motExpiryDate: "NA",
                testResult: "NA",
                odometerValue: "NA",
                completedDate: "NA",
                odometerUnit: "NA",
                motTestNumber: "NA"
            }

            if (vehicleData) {

                var motData = JSON.parse(JSON.stringify(vehicleData))[0]
                mailData.vehicleRegistration = motData.registration
                mailData.vehicleMake = motData.make
                mailData.fuelType = motData.fuelType
                mailData.vehicleModel = motData.model
                mailData.primaryColor = motData.primaryColour
                if (motData.manufactureYear) {
                    mailData.manufactureYear = motData.manufactureYear
                }
                if (motData.firstUsedDate) {
                    mailData.firstUsedDate = motData.firstUsedDate
                }
                if (motData.motTestExpiryDate) {
                    mailData.motExpiryDate = motData.motTestExpiryDate
                }
                else {
                    var motTests = motData.motTests[0]
                    if (motTests) {
                        if (motTests.expiryDate) {
                            mailData.motExpiryDate = motTests.expiryDate
                        }
                        if (motTests.completedDate) {
                            mailData.completedDate = motTests.completedDate
                        }
                        if (motTests.testResult) {
                            mailData.testResult = motTests.testResult
                        }
                        if (motTests.odometerValue) {
                            mailData.odometerValue = motTests.odometerValue
                        }
                        if (motTests.odometerUnit) {
                            mailData.odometerUnit = motTests.odometerUnit
                        }
                        if (motTests.motTestNumber) {
                            mailData.motTestNumber = motTests.motTestNumber
                        }

                    }
                }
            }
            this.bookingData.mailData = mailData
            this.bookingData.registered = true;


            // Create formData instance to pass the data to the API
            let formData = {
                regNo: this.bookingData.reg_no,
                name: this.bookingData.your_name ? this.bookingData.your_name :'Name',
                phone: this.bookingData.phone_number ? this.bookingData.phone_number :'0000000000',
                email: this.bookingData.email_address ? this.bookingData.email_address : 'Email',
                post: this.bookingData.post_code,
                garageName: this.isFromStation ? this.bookingData.selectedStation.name : 'Test Garage',
                garagePhone: this.isFromStation ? this.bookingData.selectedStation.phone : '9049248689',
                garagePostCode: this.bookingData.post_code,
                station: this.isFromStation ? this.bookingData.selectedStation.id : 769,
                data: this.bookingData.mailData,
                source: this.bookingData.cmSource ? this.bookingData.cmSource : this.bookingData.source,
                serviceCode: this.motType ? this.motType : 1
            }
            // Add header for the API
            const headers = {};
            this.loading = true;
            // AXIOS api call to add categories api
            this.$axios.post('/api/stations/bookMot', formData, {
                headers: headers
            }).then(response => {
                setTimeout(() => {
                    if (response.data.status == 200) {
                        this.submitSuccess = true;
                        this.loading = false;
                        this.submitFailure = false;
                        this.bookingData.registered = false;

                        localStorage.removeItem("motBookingData")
                        var current = new Date();
                        current.setDate(current.getDate() + 90)
                        const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
                        if(this.motType == 1 || this.motType == "1"){
                            //const linkUrl =`https://www.servicingstop.co.uk?utm_source=CheckMot`
                            const linkUrl = `https://bookmygarage.com/partners/checkmot/results/?distance=12&before=${date}&postcode=${this.bookingData.post_code.toUpperCase()}&p=1&p=3&vrm=${this.bookingData.reg_no.toUpperCase()}&sort=1&utm_source=${this.bookingData.cmSource ? this.bookingData.cmSource : this.bookingData.urm_source}`
                            window.location.assign(linkUrl)
                        }
                        else{
                            const linkUrl = `https://bookmygarage.com/partners/checkmot/results/?distance=12&before=${date}&postcode=${this.bookingData.post_code.toUpperCase()}&p=1&vrm=${this.bookingData.reg_no.toUpperCase()}&sort=1&utm_source=${this.bookingData.cmSource ? this.bookingData.cmSource : this.bookingData.urm_source}`
                            //const linkUrl =`https://www.servicingstop.co.uk?utm_source=CheckMot`
                            window.location.assign(linkUrl)
                        }

                        setTimeout(() => {
                            this.submitSuccess = false;
                        }, 5000)
                    } else {
                        this.submitFailure = true;
                        this.submitSuccess = false;
                        this.loading = false;
                    }
                }, 7000)

            }).catch(err => {
                this.submitFailure = true;
                this.submitSuccess = false;
                this.loading = false;
            });

        },

    }
}

