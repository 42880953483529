
import Header from '@/components/core/Header';
import Footer from '@/components/core/Footer';
export default {
  components: {
    Header,
    Footer
  },
  head() {
    return {
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: 'https://checkmot.com' + this.$route.path
        }
      ]
    }
  }
}

