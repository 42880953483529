// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/uknumberplate/uknumberplate-webfont.woff");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:'uknumberplate';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:normal;font-style:normal;font-display:swap}.loading-page[data-v-9c76e292]{position:absolute;width:100%;height:100vh;z-index:9999;top:0}.preloader_area[data-v-9c76e292]{background:#fff;height:100vh;display:flex;justify-content:center;align-items:center;overflow:hidden}.preloader[data-v-9c76e292]{position:absolute;top:50%;left:50%;width:164px;height:140px;transform:translate(-50%, -50%)}\n", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
