export default () => {
    const script = document.createElement('script')
    script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-133122816-1'
    script.text = ``
    script.async = true
    document.getElementsByTagName('head')[0].appendChild(script);

    const script2 = document.createElement('script') 
    script2.text = `window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'UA-133122816-1');
    gtag('config', 'AW-767089523');
    gtag('config', 'AW-632187223');`
    document.getElementsByTagName('head')[0].appendChild(script2);
  };
