import Vue from 'vue'
import moment from "moment";

import VueCryptojs from 'vue-cryptojs'

Vue.use(VueCryptojs)

Vue.mixin({
  methods: {
    scrollToJs(element) {
      var el = document.getElementById(element)
      if (el) {
        window.scroll({
          behavior: 'smooth',
          left: 0,
          top: el.offsetTop - 130
        });
      }
    },

    scrollToBottom(element) {
      var el = document.getElementById(element)
      if (el) {
        window.scrollTo(0, document.body.scrollHeight);
      }
    },

    setSessionStorage(key, value) {
      const encryptedText = this.$CryptoJS.AES.encrypt(value, "321tomkcehc").toString()
      sessionStorage.setItem(key, encryptedText);
      const decryptedText = this.$CryptoJS.AES.decrypt(encryptedText, "321tomkcehc").toString(this.$CryptoJS.enc.Utf8)
      //return {encryptedText , decryptedText}
    },

    getSessionStorage(key) {
      let sessionValue = sessionStorage.getItem(key);
      if (sessionValue) {
        const decryptedText = this.$CryptoJS.AES.decrypt(sessionValue, "321tomkcehc").toString(this.$CryptoJS.enc.Utf8)
        return JSON.parse(decryptedText)
      }
      return null
    },

    setFallbackImageUrl(event) {
      event.target.src = require(`@/assets/img/carlogo.svg`)
    },

    imgUrl(img) {
      if (img) {
        try {
          return img
        } catch (e) {
          return require('@/assets/img/carlogo.svg')
        }
      }
      return require('@/assets/img/carlogo.svg')
    }
  }
})