export default () => {
    const script = document.createElement('script') 
    script.text = `window.addEventListener('load', function () {
      if(document.getElementById("garageClick")){
        document.getElementById("garageClick").onclick = function(){
          gtag('event', 'conversion', {
            'send_to': 'AW-632187223/cD_iCLni5pQDENfSua0C'
          });
        }
      }
    });`
    document.getElementsByTagName('head')[0].appendChild(script);
  };
