export const state = () => ({
  isLogIn: false,
  showSeoPackages: true,
  profile: null,
  isUnsub: false,
  })
  
  export const mutations = {
    login(state) {
      state.isLogIn = true;
    },
    unsub(state) {
      state.isUnsub = true;
    },
    profile(state,val) {
      state.profile = val;
    },
    islogingin(state,val) {
        if(val){
        state.isLogIn = true;
        }
        else{
        state.isLogIn = false;
        }
      },
      isUnSubin(state,val) {
        if(val && val !="unSubscribe"){
        state.isUnsub = true;
        }
        else{
        state.isUnsub = false;
        }
      },
    logout(state) {
        if(process.browser){window.localStorage.clear()}
        state.isLogIn = false;
        state.showSeoPackages = true;
      },
      displaySeo(state,val) {
        if(val && val != 1){
        state.showSeoPackages = false;
        }
        else{
        state.showSeoPackages = true;
        }
      },
  }