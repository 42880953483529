
import Header from '@/components/core/Header';
import Footer from '@/components/core/Footer';
import BookMotFormCity from '@/components/modules/BookMotFormCity';
export default {
  components: {
    Header,
    Footer,
    BookMotFormCity
  },
  head() {
    return {
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: 'https://checkmot.com' + this.$route.path
        }
      ]
    }
  },
  data() {
    return {
      title: null,
      subtitle: null,
      paddingHeader: false,
    }
  },
  methods: {
    getTitle(value) {
      this.title = value;
    },
    getSubTitle(value) {
      this.subtitle = value;
    },
    paddingToAdd(value) {
      this.paddingHeader = value;
    },
  }
}

