
import Header from '@/components/core/Header';
import Footer from '@/components/core/Footer';
export default {
  components: {
    Header,
    Footer
  },
  head() {
    return {
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: 'https://checkmot.com' + this.$route.path
        }
      ]
    }
  },
  data() {
    return {
      newsDate: null,
      title: null,
      subtitle: null,
    }
  },
  methods: {
    getTitle(value) {
      this.title = value;
    },
    getSubTitle(value) {
      this.subtitle = value;
    },
    getNewsDate(value) {
      this.newsDate = value;
    },
  }
}

