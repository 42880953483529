// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/uknumberplate/uknumberplate-webfont.woff");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:'uknumberplate';src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:normal;font-style:normal;font-display:swap}.page-start-padding--internal[data-v-eff45ed8]{margin-top:5.5rem}.internal-banner[data-v-eff45ed8]{padding:20px;background-color:#4b8dfd;width:100%}.internal-banner h1[data-v-eff45ed8]{font-style:normal;font-weight:900;line-height:49px;font-size:36px;color:#fff}@media (max-width: 767px){.internal-banner h1[data-v-eff45ed8]{display:block;text-align:center;display:block;padding-left:0;font-size:25px;line-height:34px}}.internal-banner h1 img[data-v-eff45ed8]{display:block;width:76px;height:76px}@media (min-width: 768px){.internal-banner h1 img[data-v-eff45ed8]{margin-right:30px !important}}@media (max-width: 767px){.internal-banner h1 img[data-v-eff45ed8]{height:65px;width:65px;margin:auto auto 13px}}.padding-100[data-v-eff45ed8]{padding-bottom:100px}\n", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
