import Vue from 'vue'
import {
  ValidationObserver,
  ValidationProvider,
  extend
} from 'vee-validate'
import { required, max, email, min, confirmed, alpha_dash, alpha_spaces, numeric, digits, length } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'This field is required'
})
extend('alpha_spaces', alpha_spaces)
extend('alpha_dash', alpha_dash)
extend('max', max)
extend('email', email)
extend('confirmed', confirmed)
extend('length', length)
extend('digits', digits)
extend('numeric', numeric)

extend('min', {
  validate(value, { length }) {
    return value.length >= length;
  },
  params: ['length'],
  message: '{_field_} cannot be less than {length} characters'
});

extend('minmax', {
  validate(value, { min, max }) {
    return value.length >= min && value.length <= max;
  },
  params: ['min', 'max']
});

   extend('phoneNumberOrEmail', value => {
    let regExPhone = /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}\d$/
    let regExEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (regExPhone.test(value) || regExEmail.test(value) ) {
      return true;
    }
    return 'Please enter valid email or phone number';
  });
  extend('phoneNumber', value => {
    let regEx = /^(44\s?7\d{3}|\(?0?7\d{3}\)?)\s?\d{3}\s?\d{3}$/
    if (regEx.test(value)) {
      return true;
    }
    return 'Please enter valid UK phone number';
  });

  extend('regNumber', value => {
    let regEx = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/
    if (regEx.test(value)) {
      return true;
    }
    return 'Please enter valid registration number';
  });

  extend('postalCode', value => {
    let regEx = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/
    if (regEx.test(value)) {
      return true;
    }
    return 'Please enter valid UK postal code';
  });

  extend('postCode', value => {
    let regEx = /^[a-zA-Z0-9]{2,4} ?[a-zA-Z0-9]{3}$/;
    let space = /^[^-\s][a-zA-Z0-9_\s-]+$/;
    if (regEx.test(value) || space.test(value)) {
      return true;
    }
    return 'Please enter valid UK postal code';
  });


  extend('url', value => {
    let regEx = /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
    if (regEx.test(value)) {
      return true;
    }
    return 'Please enter valid url';
  });

  extend('password', {
    params: ['target'],
    validate(value, { target }) {
      return value === target;
    },
    message: 'Password confirmation does not match'
  });

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)